const alaCarteItems = [
  {
    category: "晶櫻燴經典",
    items: [
      { name: "帝王蟹米粉鍋", price: "1280" },
      { name: "蘿蔓蝦鬆", price: "480" },
      { name: "東坡肉", price: "580" },
      { name: "韭黃鱔糊", price: "420" },
      { name: "蹄筋烏參", price: "720" },
      { name: "乾燒蝦仁", price: "460" },
      { name: "清炒蝦仁", price: "690" },
      { name: "椒鹽軟絲", price: "480" },
      { name: "金沙軟絲", price: "520" },
      { name: "上海油爆蝦", price: "290" },
      { name: "XO醬干貝蘆筍蝦仁", price: "580" },
    ],
  },
  {
    category: "冷盤",
    items: [
      { name: "紹興醉雞", price: "490" },
      { name: "美味素鵝", price: "240" },
      { name: "無錫脆鱔", price: "380" },
      { name: "銀絲蜇皮", price: "280" },
      { name: "涼拌檸檬牛", price: "390" },
      { name: "龍蝦沙拉", price: "1580" },
    ],
  },
  {
    category: "肉類",
    items: [
      { name: "蠔油牛肉", price: "390" },
      { name: "韭黃牛肉", price: "390" },
      { name: "黑胡椒牛肉", price: "390" },
      { name: "芥蘭牛肉", price: "390" },
      { name: "宮保雞丁", price: "320" },
      { name: "醬爆雞丁", price: "320" },
      { name: "日式唐揚雞", price: "320" },
      { name: "乾絲肉絲", price: "280" },
      { name: "上海肴豬腳", price: "588" },
    ],
  },
  {
    category: "排骨",
    items: [
      { name: "無錫排骨", price: "380" },
      { name: "蔥㸆排骨", price: "380" },
      { name: "椒鹽排骨", price: "360" },
      { name: "孜然排骨", price: "360" },
      { name: "糖醋嫩子排", price: "360" },
      { name: "京都嫩子排", price: "360" },
      { name: "鮝烤排骨", price: "460" },
    ],
  },
  {
    category: "鮮蝦",
    items: [
      { name: "晶櫻燴招牌蝦", price: "390" },
      { name: "椒鹽鮮蝦", price: "390" },
      { name: "避風塘鮮蝦", price: "420" },
    ],
  },
  {
    category: "季節時蔬",
    items: [
      { name: "雪菜百葉", price: "280" },
      { name: "清炒高麗菜", price: "280" },
      { name: "櫻花蝦高麗菜", price: "330" },
      { name: "山藥百果蘆筍", price: "420" },
      { name: "清炒豆苗", price: "320" },
      { name: "碧綠蝦仁", price: "480" },
      { name: "豆苗蝦仁", price: "520" },
      { name: "豆苗雙冬", price: "320" },
      { name: "清炒角瓜", price: "320" },
      { name: "蟹粉角瓜", price: "380" },
      { name: "干貝角瓜", price: "420" },
      { name: "蛤蜊角瓜", price: "420" },
      { name: "清炒芥蘭", price: "260" },
      { name: "蠔油芥蘭", price: "260" },
      { name: "雙菇花椰菜", price: "380" },
    ],
  },
  {
    category: "烤物",
    items: [
      { name: "烤現流深海魚", price: "時價" },
      { name: "烤午仔魚一夜干", price: "300" },
      { name: "鹽烤鯖魚", price: "280" },
      { name: "烤帝王蟹腳", price: "1680" },
    ],
  },
  {
    category: "炸物",
    items: [
      { name: "日式唐揚雞", price: "280" },
      { name: "塔塔蘿蔓蝦", price: "320" },
      { name: "炸蝦天婦羅", price: "320" },
    ],
  },
  {
    category: "主食 ｜ 年糕",
    items: [
      { name: "醬爆蟹年糕", price: "1780" },
      { name: "上海蟹粉菜飯", price: "380" },
      { name: "蝦仁蛋炒飯", price: "280" },
      { name: "肉絲蛋炒飯", price: "260" },
      { name: "雪菜肉絲年糕", price: "360" },
      { name: "白菜肉絲年糕", price: "360" },
      { name: "蟹粉泡飯", price: "320" },
      { name: "上海菜飯", price: "280" },
      { name: "螺絲捲6顆", price: "260" },
      { name: "白飯", price: "20" },
    ],
  },
  {
    category: "豆腐 ｜ 蛋",
    items: [
      { name: "蟹黃豆腐", price: "380" },
      { name: "老皮嫩肉", price: "320" },
      { name: "紅燒豆腐", price: "320" },
      { name: "蝦仁炒蛋", price: "380" },
      { name: "吻仔魚烘蛋", price: "280" },
    ],
  },
  {
    category: "湯品",
    items: [
      { name: "酸辣湯", price: "220" },
      { name: "酸菜肚片湯", price: "340" },
      { name: "雪菜肉絲湯", price: "320" },
      { name: "砂鍋醃篤鮮", price: "460" },
      { name: "魚鮝蛤蜊排骨湯", price: "580" },
      { name: "薑絲蛤蜊湯", price: "360" },
    ],
  },
  {
    category: "生魚片",
    items: [
      { name: "綜合生魚片(2-4人)", price: "580" },
      { name: "綜合生魚片(4-6人)", price: "880" },
      { name: "綜合生魚片(6-8人)", price: "1280" },
      { name: "精選刺身盛合", price: "1680" },
      { name: "極上刺身盛合", price: "2200" },
    ],
  },
];

export default alaCarteItems;
