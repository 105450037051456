import "./MobileNavBar.css";
import MobileNavItems from "./MobileNavItems";
import NavItems from "./NavItems";

const MobileNavBar = ({ handleClick }) => {
  return (
    <div className="mobile-nav">
      <div className="mobile-nav-background" onClick={handleClick}></div>
      <div className="mobile-nav-bar">
        <div className="brand-logo">
          <img src="logo.png" alt="Logo" />
        </div>

        <div className="mobile-nav-links" onClick={handleClick}>
          <MobileNavItems />
        </div>
      </div>
    </div>
  );
};

export default MobileNavBar;
