const menus = [
  {
    name: "$9,800",
    items: [
      "龍蝦沙拉",
      "招牌刺身盛合",
      "上海肴豬腳",
      "鮮香金沙軟絲",
      "乾燒海大蝦",
      "櫻花蝦高麗菜",
      "清蒸海上鮮",
      "上海蟹粉菜飯",
      "砂鍋醃篤鮮",
      "精緻甜點",
      "時令水果盤",
    ],
  },
  {
    name: "$12,800",
    items: [
      "龍蝦雙味拼盤",
      "精選刺身盛合",
      "御品東坡肉",
      "紅燒蹄筋烏參",
      "蒜蓉海大蝦",
      "瑤柱干貝絲瓜",
      "清蒸深海魚",
      "帝王蟹米粉",
      "巴西蘑菇雞湯",
      "精緻甜點",
      "時令水果盤",
    ],
  },
  {
    name: "$16,800",
    items: [
      "龍蝦三味拼盤",
      "特上刺身盛合",
      "御品東坡肉",
      "紅燒蹄筋烏參",
      "香烤帝王蟹",
      "翠庭碧綠蝦仁",
      "清蒸深海魚",
      "上海蟹粉菜飯",
      "巴西蘑菇雞湯",
      "精緻甜點",
      "時令水果盤",
    ],
  },
  {
    name: "$21,800",
    items: [
      "龍蝦三味拼盤",
      "特上刺身盛合",
      "頂級肋眼牛排",
      "紅燒鮑魚烏參",
      "香烤帝王蟹",
      "XO醬干貝蘆筍蝦仁",
      "清蒸深海魚",
      "松露蝦仁炒飯",
      "砂鍋火烔雞湯",
      "精緻甜點",
      "時令水果盤",
    ],
  },
  {
    name: "$31,800",
    items: [
      "鮑魚帝王蟹沙拉",
      "極上刺身盛合",
      "香烤A5和牛",
      "廣島生蠔佐海膽",
      "活烤帝王蟹",
      "XO醬干貝蘆筍蝦仁",
      "極致深海魚",
      "紅燒鮑魚烏參",
      "松露鮭魚卵炒飯",
      "砂鍋火烔雞排翅",
      "精緻甜點",
      "時令水果盤",
    ],
  },
];

export default menus;
