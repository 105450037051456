import React from "react";
import BurgerMenu from "./BurgerMenu";
import NavItems from "./NavItems";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="top-navbar">
      <div className="brand-logo">
        <a href="/">
          <img src="logo.png" alt="Logo" />
        </a>
      </div>
      <nav className="navbar">
        <NavItems />
      </nav>
    </div>
  );
};

export default Navbar;
