import React from "react";
import { debounce } from "lodash";
import { useState, useEffect } from "react";
import "./Header.css";
import Navbar from "./Navbar";
import StickyNavBar from "./StickyNavBar";

const Header = () => {
  const handleScroll = () => {
    const header = document.querySelector(".header");
    const stickyNavbar = document.querySelector(".sticky-navbar");

    if (window.scrollY > header.offsetHeight) {
      stickyNavbar.style.display = "flex";
    } else {
      stickyNavbar.style.display = "none";
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className="header">
        <Navbar />
        <StickyNavBar />
      </div>
    </>
  );
};

export default Header;
