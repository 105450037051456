import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./views/Home";
import Careers from "./views/Careers";
import ContactUs from "./views/ContactUs";
import AboutUs from "./views/AboutUs";
import Events from "./views/Events";
import Menu from "./views/Menu";
import Footer from "./components/Footer";
import Reservations from "./views/Reservations";
import Album from "./views/Album";
import Copyright from "./components/Copyright";
import SetMenu from "./views/SetMenu";
import Privacy from "./views/Privacy";
import Policy from "./views/Policy";
import AlaCarteMenu from "./views/AlaCarteMenu";

const App = () => {
  return (
    <div className="container">
      <Router>
        <Header />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/set-menu" element={<SetMenu />} />
            <Route path="/room-menu" element={<Menu />} />
            <Route path="/alacarte" element={<AlaCarteMenu />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/events" element={<Events />} />
            <Route path="/reservations" element={<Reservations />} />
            <Route path="/album" element={<Album />} />
            <Route path="/policy" element={<Policy />} />
          </Routes>
        </div>
        <Footer />
        <Copyright />
      </Router>
    </div>
  );
};

export default App;
