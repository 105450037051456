import React from "react";
import "./Privacy.css";

const Privacy = () => {
  return (
    <>
      <div className="privacy-container">
        <h2>隱私權條款</h2>
        <p>
          非常歡迎您光臨「晶櫻燴Erito官方網站」（以下簡稱本網站），為了讓您能夠安心的使用本網站的各項服務與資訊，特此向您說明本網站的隱私權保護政策，以保障您的權益，請您詳閱下列內容：
        </p>
        <p>一、隱私權保護政策的適用範圍</p>
        <p>
          隱私權保護政策內容，包括本網站如何處理在您使用網站服務時收集到的個人識別資料。隱私權保護政策不適用於本網站以外的相關連結網站，也不適用於非本網站所委託或參與管理的人員。
        </p>
        <p>二、個人資料的蒐集、處理及利用方式</p>
        <p>
          當您造訪本網站或使用本網站所提供之功能服務時，我們將視該服務功能性質，請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您書面同意，本網站不會將個人資料用於其他用途。
        </p>
        <p>
          本網站在您使用服務信箱、問卷調查等互動性功能時，會保留您所提供的姓名、電子郵件地址、聯絡方式及使用時間等。
        </p>
        <p>
          於一般瀏覽時，伺服器會自行記錄相關行徑，包括您使用連線設備的IP位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等，做為我們增進網站服務的參考依據，此記錄為內部應用，決不對外公佈。
        </p>
        <p>
          為提供精確的服務，我們會將收集的問卷調查內容進行統計與分析，分析結果之統計數據或說明文字呈現，除供內部研究外，我們會視需要公佈統計數據及說明文字，但不涉及特定個人之資料。
        </p>
        <p>三、資料之保護</p>
        <p>
          本網站主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。
        </p>
        <p>
          如因業務需要有必要委託其他單位提供服務時，本網站亦會嚴格要求其遵守保密義務，並且採取必要檢查程序，以確保其確實遵守。
        </p>
        <p>四、網站對外的相關連結</p>
        <p>
          本網站的網頁提供其他網站的網路連結，您也可透過本網站的連結前往其他網站。不過，連結網站不適用本網站的隱私權政策，您必須參考該連結網站中的隱私權保護政策。
        </p>
        <p>五、與第三人共用個人資料之政策</p>
        <p>
          本網站絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或契約義務者不在此限。
        </p>
        <p>前項但書之情形包括不限於：</p>
        <ul>
          <li>經由您書面同意。</li>
          <li>法律明文規定。</li>
          <li>為免除您生命、身體、自由或財產上之危險。</li>
          <li>與公共利益有關。</li>
          <li>為免除本網站重大危害之危險。</li>
        </ul>
        <p>六、Cookie之使用</p>
        <p>
          為了提供您最佳的服務，本網站會在您的電腦中放置並取用我們的Cookie，若您不願接受Cookie的寫入，您可在您的瀏覽器內設定隱私權等級，設定高階您可拒絕Cookie寫入，但可能導致部份功能無法正常執行。
        </p>
        <p>七、隱私權保護政策之修正</p>
        <p>
          本網站隱私權保護政策將因應需求有修改之可能，修改後的條款內容將公佈於本網站上，不另行通知。
        </p>
        <p>如果您有任何疑問或建議，歡迎聯絡我們。</p>
      </div>
    </>
  );
};

export default Privacy;
