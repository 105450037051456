import React from "react";
import "./Policy.css";

const Policy = () => {
  return (
    <>
      <div className="policy-container">
        <h2>網站使用條款</h2>
        <p>最後更新日期：2023年8月19日</p>

        <p>
          1.
          接受條款通過訪問和使用晶櫻燴Erito（以下簡稱“本網站”），你同意遵循本網站的使用條款。如果你不同意本條款，請勿使用本網站。
        </p>
        <p>
          2.
          使用限制你不得使用本網站進行任何非法、詐騙或有害的活動，或者侵犯他人的權利。
        </p>
        <p>
          3.
          免責聲明本網站及其內容按“原樣”提供，不作任何明示或暗示的保證。晶英燴Erito不保證本網站的運作不會中斷或無誤。
        </p>
        <p>
          4.
          著作權本網站的內容受到著作權保護，未經晶英燴Erito明確同意，不得複製、分發或以其他方式使用。
        </p>
        <p>
          5.
          第三方鏈接本網站可能包含指向第三方網站的鏈接。對於這些外部網站的內容或實踐，晶英燴Erito不承擔任何責任。
        </p>
        <p>
          6.
          修改晶英燴Erito保留隨時修改本使用條款的權利。修改後的條款將於本頁上發布。
        </p>
        <p>
          7.
          聯絡信息如果你有任何問題或建議，請通過contact@erito.com.tw與我們聯絡。
        </p>
        <p>© 2023 晶英燴Erito，保留所有權利。</p>
      </div>
    </>
  );
};

export default Policy;
