import React from "react";

const SetMenu = () => {
  return (
    <>
      <div className="menu-container">
        <img src="./s1.jpg" />
      </div>
      <div className="menu-container">
        <img src="./s2.jpg" />
      </div>
      <div className="menu-container">
        <img src="./s3.jpg" />
      </div>
      <div className="menu-container">
        <img src="./s4.jpg" />
      </div>
      <div className="menu-container">
        <img src="./s5.jpg" />
      </div>
    </>
  );
};

export default SetMenu;
