import { useState } from "react";
import "./BurgerMenu.css";
import MobileNavBar from "./MobileNavBar";

const BurgerMenu = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <div className="burger-menu" onClick={handleClick}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
      {open ? <MobileNavBar handleClick={handleClick} /> : null}
    </>
  );
};

export default BurgerMenu;
