import React from "react";
import alaCarteItems from "../data/alaCarteData"; // Import the à la carte menu data
import "./AlaCarteMenu.css";

const AlaCarteMenu = () => {
  return (
    <div className="ala-carte-container">
      {alaCarteItems.map((category, index) => (
        <div key={index} className="ala-carte-category">
          <h2>{category.category}</h2>
          <ul>
            {category.items.map((item, idx) => (
              <li key={idx} className="ala-carte-item">
                <span className="name">{item.name}</span>
                <span className="price">{item.price}</span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default AlaCarteMenu;
