import React from "react";
import Link from "./Link";

const MobileNavItems = () => {
  return (
    <div className="mobile-nav-contents">
      {/* <Link href="/set-menu" className="nav-link">
        優惠活動
      </Link> */}
      <Link href="/set-menu" className="nav-link">
        單點菜單
      </Link>
      <Link href="/room-menu" className="nav-link">
        桌菜菜單
      </Link>
    </div>
  );
};

export default MobileNavItems;
