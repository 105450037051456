const selectedDishes = [
  {
    src: "/f1.jpg",
    name: "御品東坡肉",
    description: "",
  },
  {
    src: "/f2.jpg",
    name: "砂鍋醃篤鮮",
    description: "",
  },
  {
    src: "/f3.jpg",
    name: "干絲肉絲",
    description: "",
  },
  {
    src: "/f4.jpg",
    name: "蟹黃豆腐",
    description: "",
  },
  {
    src: "/f5.jpg",
    name: "刺身盛合",
    description: "",
  },
  {
    src: "/f6.jpg",
    name: "宮保雞丁",
    description: "",
  },
];

export default selectedDishes;
