import React from "react";
import "./Copyright.css";

const Copyright = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="copyright-container">
      <p>
        Copyright © {currentYear} 晶英燴餐飲事業有限公司 All rights reserved
      </p>
    </div>
  );
};

export default Copyright;
