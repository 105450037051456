import React from "react";
import "./Footer.css";
import Link from "./Link";
const Footer = () => {
  return (
    <>
      {/* None react style, optimization needed */}
      <section className="footer">
        <div className="footer-content">
          <img src="./flogo.png"></img>
          <p>台北市大安區復興南路一段107巷34號</p>
          <p>(02)2771-1182</p>
          <p>contact@erito.com.tw</p>
          <div className="icons">
            <a href="https://www.facebook.com/profile.php?id=100094405576355">
              <i class="bx bxl-facebook-square"></i>
            </a>
            <a href="#">
              <i class="bx bxl-instagram"></i>
            </a>
          </div>
        </div>
        <div className="footer-content">
          <h4>快速連結</h4>
          <li>
            <a href="#">精選佳餚</a>
          </li>
          <li>
            <a href="https://inline.app/booking/erito/erito">立即訂位</a>
          </li>
          <li>
            <a href="#">優惠活動</a>
          </li>
          <li>
            <a href="#">美食部落</a>
          </li>
        </div>
        <div className="footer-content">
          <h4>餐廳資訊</h4>
          <li>
            <Link href="/privacy" className="link-item">
              隱私政策
            </Link>
          </li>
          <li>
            <Link href="/policy" className="link-item">
              使用條款
            </Link>
          </li>
          <li>
            <a href="#">精選影片</a>
          </li>
          <li>
            <a href="#">誠徵夥伴</a>
          </li>
        </div>
        <div className="footer-content">
          <h4>營業時間 Hours</h4>
          <li>
            <span>午餐 Lunch:</span>
          </li>
          <li>
            <span>11:30-14:30</span>
          </li>
          <li>
            <span>晚餐 Dinner:</span>
          </li>
          <li>
            <span>17:00-21:30</span>
          </li>
        </div>
      </section>
    </>
  );
};

export default Footer;
