import React from "react";
import NavItems from "./NavItems";
import BurgerMenu from "./BurgerMenu";

const StickyNavBar = () => {
  return (
    <div className="navbar-container">
      <div className="sticky-navbar">
        <div className="sticky-navbar-inner">
          <div className="brand-logo">
            <img src="logo.png" alt="Logo" />
          </div>
          <nav className="navbar">
            <BurgerMenu />
          </nav>
        </div>
      </div>
    </div>
  );
};

export default StickyNavBar;
